.constant-size-textarea {
    height: 600px;
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding-bottom: 15px;
    /* Add bottom padding */
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #8B5CF6;
    /* Your theme color */
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
}

/* Keyframes for spin animation */
@-webkit-keyframes spin {

    /* Safari */
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spin-once {
    animation: spin 0.5s linear;
}



.go-back-btn {
    display: inline-flex;
    align-items: center;
    background-color: #8B5CF6;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* margin-right: 20px; */
    /* Add spacing between button and details */
}

.go-back-btn:hover {
    background-color: #7D4AC7;
}

.document-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.document-card {
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    min-height: 100px;
    max-height: 500px;
    width: 100%;
    /* or max-width if you want to limit width */
    overflow-y: auto;
}

/* Responsive adjustments with a media query */
@media (max-width: 768px) {
    .document-card {
        padding: 10px;
        min-height: 80px;
    }
}


.header-content {
    display: flex;
    align-items: center;
    width: 100%;
}

.document-details-card {
    display: flex;
    justify-content: space-around;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 5px;
}

.header-placeholder {
    /* An invisible placeholder with the same width as the Go Back button to balance the layout */
    width: 124px;
    /* Adjust this width to match the Go Back button's width */
    visibility: hidden;
}

.header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.document-details {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-grow: 1;
}

.document-detail {
    display: flex;
    flex-direction: column;
}

.detail-heading {
    font-size: 1.1rem;
    color: #6B7280;
    /* Darker gray */
    font-weight: bold;
}

.detail-content {
    font-size: 1rem;
    color: #374151;
    /* Regular gray */
}

.section-heading {
    margin-bottom: 20px;
    text-align: center;
    flex-grow: 1;
}