/* CodeMirrorEditor.css */
.cm-mips-highlight {
    color: #4338ca;
    /* Tailwind CSS indigo-700 */
}

/* CodeMirrorEditor.css */

/* Set the overall editor styles */
.CodeMirror {
    /* Width and Height */
    width: 100%;
    height: auto;
    /* Adjust as needed */

    /* Border and Border Radius */
    border: none;
    /* Remove border */
    border-radius: 0.25rem;
    /* Match 'rounded' class in Tailwind */

    /* Padding */
    padding: 0.5rem;
    /* Match 'p-2' class in Tailwind */

    /* Font and Text */
    font-family: inherit;
    /* Use the same font as your textarea */
    font-size: inherit;
    color: #475569;
    /* Tailwind 'slate-600' */

    /* Background */
    background-color: inherit;
    /* Use the same background as your page */
}

/* Remove focus outline */
.CodeMirror-focused .CodeMirror-scroll {
    outline: none;
}

/* Remove gutters (line numbers, etc.) */
.CodeMirror-gutters {
    display: none;
}

/* Remove any resizing */
.CodeMirror-resizer {
    display: none;
}

/* Adjust the scroll area */
.CodeMirror-scroll {
    /* Remove scrollbars if not needed */
    overflow: hidden;
}

/* Adjust the content area */
.CodeMirror-lines {
    padding: 0;
    /* Remove any default padding */
}

/* Remove the cursor blink rate if desired */
.CodeMirror-cursor {
    /* Customize the cursor appearance */
}

/* Disable user selection if desired */
.CodeMirror * {
    user-select: text;
}