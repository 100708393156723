.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.navbarStyle {
    background-color: #708090;
    /* Steel gray color */
}

.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* Align children (spinner and text) vertically */
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000;
    backdrop-filter: blur(2px);
}


.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #8B5CF6;
    /* Changed from blue to indigo */
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

.loadingMessage {
    color: #4A5568;
    /* Adjust color as needed */
    font-size: 1rem;
    /* Adjust font size as needed */
    font-weight: 500;
    /* Medium font weight for better visibility */
}



@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* Prevent body scrolling */
}

:root {
    --navbar-height: 64px;
    /* Adjust this to match your navbar height */
}

.content-height {
    height: calc(100vh - var(--navbar-height));
}