.p-organizationchart .p-organizationchart-line-down {
    border-left: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-left {
    border-right: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-right {
    border-left: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

.p-organizationchart .p-organizationchart-line-top {
    border-top: 1px solid #e5e7eb;
    border-color: #e5e7eb;
}

/* Center the caret icon within the toggler circle */
.p-organizationchart .p-node-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Optional: Adjust the size of the toggler circle if needed */
.p-organizationchart .p-node-toggler {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

/* Optional: Adjust the size of the caret icon if needed */
.p-organizationchart .p-node-toggler .pi {
    font-size: 1rem;
    /* Adjust the font size as needed */
}