/* TipTap.css */
.tiptap-bubble-menu {
    display: flex;
    gap: 8px;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid #ddd;
    padding: 6px;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(5px);
    /* For a subtle blur effect */
}

.tiptap-bubble-menu button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    font-size: 18px;
    color: #555;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s, color 0.2s, transform 0.1s;
    border-radius: 4px;
}

.tiptap-bubble-menu button:hover {
    background-color: #f0f0f0;
    transform: translateY(-1px);
}

.tiptap-bubble-menu button.is-active {
    color: #000;
    font-weight: bold;
}

.tiptap-bubble-menu button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
}

.copy-feedback {
    position: absolute;
    top: -40px;
    /* Adjust based on your layout */
    right: 0;
    background-color: var(--primary-color);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    animation: fadeInOut 2s forwards;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    10%,
    90% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.ProseMirror ul {
    list-style-type: disc;
    margin-left: 1.5em;
}

.ProseMirror ol {
    list-style-type: decimal;
    margin-left: 1.5em;
}

.ProseMirror li {
    margin-bottom: 0.5em;
}

/* Add padding inside the editor */
#tiptap-editor-container .ProseMirror {
    padding: 12px;
    /* Simple padding to ensure text doesn't overlap the edges */
    box-sizing: border-box;
    /* Ensures padding is inside the container */
}