/* Editor.css */

.mdm-editor-container {
    position: relative;
}

/* Style the ReactQuill editor */
.mdm-editor .ql-editor {
    white-space: pre-wrap;
    /* Preserves whitespace and line breaks */
    min-height: 150px;
    padding: 8px;
    font-size: 1rem;
    color: #4a5568;
    /* text-slate-600 */
}

/* Placeholder styling */
.mdm-editor .ql-editor::before {
    color: #a0aec0;
    /* text-slate-400 */
    font-style: italic;
}

/* Show border on focus */
.mdm-editor .ql-container.ql-snow.ql-focused {
    border: 1px solid #cbd5e0;
    /* border-gray-300 */
    box-shadow: 0 0 0 1px #cbd5e0;
}

/* Ensure unordered lists have bullets */
.mdm-editor .ql-editor ul {
    list-style-type: disc;
    /* Default bullet style */
    padding-left: 20px;
    /* Indent the list */
    margin: 0;
    /* Remove default margins if needed */
}

/* Ensure list items are displayed as block elements */
.mdm-editor .ql-editor li {
    display: list-item;
    margin-bottom: 4px;
    /* Optional: Add spacing between items */
}