.dashboard-container {
    width: 85%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
}


/* Add more styles as needed */


@media (max-width: 768px) {
    .custom-datepicker {
        width: 100%;
        /* Full width on smaller screens */
        margin-bottom: 15px;
    }
}

.feedback-link {
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
}



.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* Align children (spinner and text) vertically */
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 4px solid #4B0082;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin-bottom: 15px;
    /* Space between spinner and text */
}

.loading-text {
    color: #4A5568;
    /* Adjust color as needed */
    font-size: 1rem;
    /* Adjust font size as needed */
    font-weight: 500;
    /* Medium font weight for better visibility */
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

hr {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 10px 0;
}

/* Customizing the overall calendar pop-up */
.react-datepicker {
    border: 1px solid #d6d6d6;
    /* Light gray border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Soft shadow for depth */
    font-family: 'Arial', sans-serif;
    /* Consistent font */
}

/* Customizing the header */
.react-datepicker__header {
    background-color: #6a5acd;
    /* indigo shade for header */
    border-bottom: 1px solid #d6d6d6;
}

/* Header text color */
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: white;
}

/* Month and year dropdowns */
.react-datepicker__header select {
    color: white;
    background-color: transparent;
    border-color: #6a5acd;
}

/* Customizing the days */
.react-datepicker__day,
.react-datepicker__day-name {
    color: #333;
    /* Dark text for contrast */
}

/* Hover state for days */
.react-datepicker__day:hover,
.react-datepicker__day--keyboard-selected {
    background-color: #6a5acd;
    color: white;
}

/* Selected day style */
.react-datepicker__day--selected {
    background-color: #6a5acd;
    color: white;
}

/* Navigation buttons */
.react-datepicker__navigation {
    top: 20px;
    border-color: white;
}

.react-datepicker__navigation--previous {
    border-right-color: white;
}

.react-datepicker__navigation--next {
    border-left-color: white;
}

/* Adjusting the position of the calendar */
.react-datepicker-popper {
    z-index: 3;
}